"use client";

import { LicenseManager } from "@ag-grid-enterprise/core";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import "@ag-grid-community/styles/ag-grid.css"; // Core CSS
import "@ag-grid-community/styles/ag-theme-quartz.css"; // Theme

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-063670}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Caltana,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Caltana}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Caltana}_need_to_be_licensed___{Caltana}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_September_2025}____[v3]_[01]_MTc1ODMyMjgwMDAwMA==62523b678c546a79265e68867c8c5422",
);

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ExcelExportModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  MenuModule,
  FiltersToolPanelModule,
  SetFilterModule,
  RangeSelectionModule,
  RichSelectModule,
]);

export default function AgGridInit() {
  return null;
}
